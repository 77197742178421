<template>
<div>
    <!-- Modal -->
    <div class="modal fade" id="sms" tabindex="-1" aria-labelledby="sms" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content sms">
                <h1 class="colors-header">Отправить SMS сообщение</h1>
                <div class="smsTo">
                    Пользователю {{$store.state.operator.order_info.user.user.name}} <span>{{$store.state.operator.order_info.user.user.phone}}</span>
                </div>
                <div class="smsText">
                    <textarea  placeholder="Текст сообщения" v-model="text"></textarea>
                </div>
                <button ref="myBtn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <img :src="require('../assets/closeModal.svg')" />
                </button>
                <div class="modal-body addMenuItem"></div>
                <div class="modal-footer pb-2 mb-5 mt-3">
                    <button class="btn-oval btn-oval--brown" :disabled="isDisabled" @click="sendSms">Отправить SMS</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    data() {
        return {
            text:''
        }
    },
    computed:{
     isDisabled(){
        if(this.text.length>0){
        return false;
     } else {
       return true;
     }
     }
    },
    components: {
       
    },
    methods: {
           async sendSms() {
            const data = {
                'order_id': this.$store.state.operator.order_info.id,
                'message': this.text
            }
            const res = await this.$store.dispatch("actionSendSmsOperator", data);
            if(res.data.status){
                const elem = this.$refs.myBtn
            elem.click()
            }
          console.log(res.data.status)
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-body {
    padding: 18px 10px 0 82px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 940px;
        margin: 1.75rem auto;
    }

}
.sms{
    background: #F5F5F5;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    h1{
        text-align: center;
        font-size: 26px;
line-height: 142.69%;
    }
}
.modal-footer {
    border: none;
    display: flex;
    justify-content: center;
}



.btn-close {
     position: absolute;
    background: none;
    right: 0;
    margin: auto;
    left: 0;
    top: -67px;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.smsText{
    margin-top: 30px;
    textarea{
        width: 100%;
        height: 163px;
        resize: none;
        background: #FFFFFF;
border-radius: 12px;
padding: 18px 22px;
border:none;
&::placeholder{
   font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 142.69%;
color: #757575;
}
&:focus{
    outline: none;
}
    };
}
.smsTo{
    text-align: center;
    color: #5E5E5E;
}

</style>
